import React, { useEffect, useState } from "react";
//import axios from "axios";
import axiosModificado from "components/ConfiguracionAxios";
// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views
import DataTable from "components/Tables/Tables";
import Swal from "sweetalert2";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import ExportExcel from "components/ExportExcel";

export default function Tiendas() {
  const [tiendas, setTiendas] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener supervisores
        const responseSupervisores = await axiosModificado.get('/todos_usuarios');
        const supervisores = responseSupervisores.data.reduce((acc, usuario) => {
          acc[usuario.id] = usuario.name; // Mapea { id: name }
          return acc;
        }, {});
  
        // Obtener tiendas
        const responseTiendas = await axiosModificado.get('/todos_tiendas');
        const tiendasModificadas = responseTiendas.data.map(tienda => ({
          ...tienda,
          supervisor: supervisores[tienda.supervisor] || "Sin asignar"
        }));
  
        setTiendas(tiendasModificadas);
      } catch (error) {
        console.error('Error al obtener datos: ', error);
        Swal.fire("Error al obtener los datos", "", "error");
      }
    };
  
    fetchData();
  }, []);
  
  const fechaAhorita = new Date().toLocaleString();
  const nombre = "KAPZAR-Tiendas" + fechaAhorita;

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Nombre',
      accessor: 'nombre',
    },
    {
      Header: 'Direccion',
      accessor: 'direccion',
    },
    {
      Header: 'Telefono',
      accessor: 'telefono',
    },
    {
      Header: 'Supervisor',
      accessor: 'supervisor',
    },
  ];

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar nombre="Tiendas"/>

        <div className="relative bg-lightBlue-600 md:pt-32 pt-12 pb-10">
          <div className='px-4 md:px-10 mx-auto w-full'>
            <div className='p-4 bg-white rounded'>

              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3 className="font-semibold text-base text-blueGray-700">
                      Tiendas
                    </h3>
                  </div>
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                    <ExportExcel data={tiendas} fileName={nombre} />
                  </div>
                  {/* <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                    <Link
                      className={
                        "bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      }
                      to="/admin/tienda/crear"
                    >
                      Agregar
                    </Link>
                    
                  </div> */}
                </div>
              </div>

              {tiendas.length === 0 ? (
                <p>No hay tiendas</p>
              ) : (
                <DataTable columns={columns} data={tiendas} tipo={0} />
                
              )}
            </div>
          </div>
        </div>
        
        <div className="px-4 md:px-10 mx-auto w-full">
          
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
