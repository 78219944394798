import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
//import axios from 'axios';
import axiosModificado from 'components/ConfiguracionAxios';
import Swal from 'sweetalert2';
import Select from 'react-select';

function CrearTienda() {
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    direccion: '',
    departamento: '',
    municipio: '',
    telefono: '',
    supervisor: '',
    tipo: ''
  });
  const [selectedOptionUsuario, setSelectedOptionUsuario] = useState(null);
  const [selectedOptionTipo, setSelectedOptionTipo] = useState(null);
  const departamentos = [ "Ahuachapán", "Cabañas", "Chalatenango", "Cuscatlán", "La Libertad", "La Paz", "La Unión", "Morazán", "San Miguel", "San Salvador", "San Vicente", "Santa Ana", "Sonsonate", "Usulután" ];
  const municipios = [ "Ahuachapán Norte", "Ahuachapán Centro", "Ahuachapán Sur", "San Salvador Norte", "San Salvador Oeste", "San Salvador Este", "San Salvador Centro", "San Salvador Sur", "La Libertad Norte", "La Libertad Centro", "La Libertad Oeste", "La Libertad Este", "La Libertad Costa", "La Libertad Sur", "Chalatenango Norte", "Chalatenango Centro", "Chalatenango Sur", "Cuscatlán Norte", "Cuscatlán Sur", "Cabañas Este", "Cabañas Oeste", "La Paz Oeste", "La Paz Centro", "La Paz Este", "La Unión Norte", "La Unión Sur", "Usulután Norte", "Usulután Este", "Usulután Oeste", "Sonsonate Norte", "Sonsonate Centro", "Sonsonate Este", "Sonsonate Oeste", "Santa Ana Norte", "Santa Ana Centro", "Santa Ana Este", "Santa Ana Oeste", "San Vicente Norte", "San Vicente Sur", "San Miguel Norte", "San Miguel Centro", "San Miguel Oeste", "Morazán Norte", "Morazán Sur" ];

  /* const tipos = ['Tipo1', 'Tipo2', 'Tipo3']; */

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleInputChangeTelefono = (e) => {
    const { name, value } = e.target;
    const formattedValue = value.replace(/\D/g, '').slice(0, 8);
    setFormData({ ...formData, [name]: formattedValue });
  };

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Datos enviados:', formData);
    try {
      const response = await axiosModificado.post('/agregar_tienda', formData);
      console.log('Respuesta del servidor:', response.data);
      console.log('Longitud de respuesta:', response.data.length);
      if( response.data.nombre != "" ){
        Swal.fire({
          title: 'Tienda agregada',
          text: '',
          icon: 'success',
          confirmButtonText: 'Ir a tiendas'
        }).then((result) => {
          history.push('/admin/tiendas');
        });
      } else {
        Swal.fire("Error en agregar Tienda", "", "error");
      }
      //alert("se agrego tienda");
    } catch (error) {
      console.error('Error al enviar datos:', error);
      Swal.fire("Error en guardar", "", "Error");
    }
  };

  const [tipo, setTipo] = useState([ {value: '1', label: 'Centro'}, {value: '2', label: 'Distribuidora'}, ]);
  const [usuario, setUsuarios] = useState([]);
  useEffect(() => {
      axiosModificado.get('/todos_usuario_supervisores_for_select')
      .then( response => {
        setUsuarios(response.data);
      })
      .catch(error => {
        console.error('Error al obtener datos de la API:', error);
      }); 
  }, []);

  return (
    <>
      <div className="relative bg-lightBlue-600 md:pt-32 pb-32 pt-12">
        <div className='px-4 md:px-10 mx-auto w-full'>
          <div className='p-4 bg-white rounded'>
            <h6 className="text-blueGray-500 text-sm font-bold">Crear Tienda</h6>
            <form onSubmit={handleSubmit}>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Nombre:
                </label>
                <input type="text" name="nombre" value={formData.nombre} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Direccion:
                </label>
                <input type="text" name="direccion" value={formData.direccion} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Departamento:
                </label>
                <select
                  name="departamento"
                  value={formData.departamento}
                  onChange={handleInputChange}
                >
                  <option value="">Selecciona un departamento</option>
                  {departamentos.map((dept, index) => (
                    <option key={index} value={dept}>
                      {dept}
                    </option>
                  ))}
                </select>
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Municipio:
                </label >
                <select
                  name="municipio"
                  value={formData.municipio}
                  onChange={handleInputChange}
                >
                  <option value="">Selecciona un municipio</option>
                  {municipios.map((muni, index) => (
                    <option key={index} value={muni}>
                      {muni}
                    </option>
                  ))}
                </select>
              </div>

              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Telefono:
                </label >
                <input type="text" name="telefono" value={formData.telefono} onChange={handleInputChangeTelefono} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Supervisor:
                </label >
                <Select options={usuario} name='supervisor' placeholder="Seleccione Usuario" onChange={(selectedOptionUsuario)=>{
                    setSelectedOptionUsuario(selectedOptionUsuario);
                    setFormData({ ...formData, supervisor: selectedOptionUsuario ? selectedOptionUsuario.value : '' });
                }} />
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Tipo:
                </label >
                <Select options={tipo} name='tipo' placeholder="Seleccione Tipo" onChange={(selectedOptionTipo)=>{
                    setSelectedOptionTipo(selectedOptionTipo);
                    setFormData({ ...formData, tipo: selectedOptionTipo ? selectedOptionTipo.value : '' });
                }} />
              </div>
              
              <button className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type="submit">Enviar</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default CrearTienda;
