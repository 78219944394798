import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const ExportExcel = ({ data, fileName }) => {
  const exportToExcel = () => {
    // Mapeo de nombres de columnas en español
    const columnMapping = {
       created_at: "fecha de creación",
      updated_at: "fecha de última edición"
    };

    // Transformar los datos: renombrar claves y eliminar "tipo"
    const transformedData = data.map(item => {
      const newItem = {};
      Object.keys(item).forEach(key => {
        if (key !== "tipo") { // Excluir el campo "tipo"
          const newKey = columnMapping[key] || key;
          newItem[newKey] = item[key];
        }
      });
      return newItem;
    });

    // Crear hoja de cálculo con los datos filtrados
    const ws = XLSX.utils.json_to_sheet(transformedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, `${fileName}.xlsx`);
  };

  return (
    <button onClick={exportToExcel}>
      Exportar a Excel
    </button>
  );
};

export default ExportExcel;
