import React, { useEffect, useState } from "react";
//import axios from "axios";
import axiosModificado from "components/ConfiguracionAxios";
// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views
import DataTable from "components/Tables/TablesExcel";
import Swal from "sweetalert2";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import ExportExcel from "components/ExportExcel";

function secondsToTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return { hours, minutes };
}

export default function Reporte2() {
  const fechaAhorita = new Date().toLocaleString();
  const nombre = "Reporte-usuarios_" + fechaAhorita;
  const [tiendas, setTiendas] = useState([]);
  useEffect(() => {
      axiosModificado.get('/reporte2')
      .then( response => {
        setTiendas(response.data);
      })
      .catch(error => {
        console.error('Error al obtener datos de la API: [/reporte1]', error);
        Swal.fire("Error en obtener las tiendas", "", "error");
      });
  }, []);

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Horas Trabajadas',
      accessor: 'total',
    },
    {
      Header: 'Nombre del Usuario',
      accessor: 'name',
    },
  ];

  const [formData, setFormData] = useState({
    start_date: '',
    end_date: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      const response = await axiosModificado.post('/reporte2_fechas', formData);
      console.log(response.data)
      setTiendas(response.data)
    } catch (error) {
      
    }
  }

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar nombre="Reporte Vendedeores"/>

        <div className="relative bg-lightBlue-600 md:pt-32 pt-12 pb-10">
          <div className='px-4 md:px-10 mx-auto w-full'>
            <div className='p-4 bg-white rounded'>

              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3 className="font-semibold text-base text-blueGray-700">
                      Reporte Vendedores
                    </h3>
                  </div>
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                    <ExportExcel data={tiendas} fileName={nombre} />
                  </div>
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                    <Link
                      className={
                        "bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      }
                      to="/admin/tienda/crear"
                    >
                      Agregar
                    </Link>
                    
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit}>
                    <div className='relative w-full mb-3'>
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Fecha:
                      </label >
                      <input type="date" name="start_date" value={formData.start_date} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                    </div>
                    <div className='relative w-full mb-3'>
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Fecha:
                      </label >
                      <input type="date" name="end_date" value={formData.end_date} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                    </div>
                    <button className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type="submit">Enviar</button>

              </form>

              {tiendas.length === 0 ? (
                <p>No hay tiendas</p>
              ) : (
                <DataTable columns={columns} data={tiendas} tipo={0} />
                
              )}
            </div>
          </div>
        </div>
        
        <div className="px-4 md:px-10 mx-auto w-full">
          
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
